<template>
  <div>
    <vx-card actionable class="cardx" title="Fees & Books(CMA)">
      <vs-row>
        <!-- <vs-col style="margin-bottom: 7%">
        <leads-nav-bar />
        </vs-col>-->
        <vs-col>
          <vs-row>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="10">
              <v-select
                style="z-index: 1000;  margin-top: 4%; margin: 10px;"
                class="w-full"
                placeholder="Filters"
                v-model="select1"
                :options="options1"
              ></v-select>
              <v-select
                style="z-index: 1000;  margin-top: 4%; margin: 10px;"
                class="w-full"
                placeholder="Fees Filters"
                v-model="select2"
                :options="options2"
              ></v-select>
              <v-select
                style="z-index: 1000;  margin-top: 4%; margin: 10px;"
                class="w-full"
                placeholder="Books Filters"
                v-model="select3"
                :options="options3"
              ></v-select>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
              <vs-dropdown class="gap" vs-custom-content vs-trigger-click style="margin-right: 1%">
                <vs-button radius color="primary" icon="search"></vs-button>
                <vs-dropdown-menu class="loginx">
                  <vs-row vs-w="12" style="margin-bottom: 2%">
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                      <vs-input type="text" label-placeholder="Name" v-model="value1" />
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                      <vs-button color="dark" type="gradient" icon="search" @click="search('name')"></vs-button>
                    </vs-col>
                  </vs-row>
                  <vs-row vs-w="12" style="margin-bottom: 2%">
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                      <vs-input type="text" label-placeholder="Can.No" v-model="value2" />
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                      <vs-button
                        color="dark"
                        type="gradient"
                        icon="search"
                        @click="search('candidate_id')"
                      ></vs-button>
                    </vs-col>
                  </vs-row>
                  <vs-row vs-w="12" style="margin-bottom: 2%">
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                      <vs-input type="text" label-placeholder="Batch" v-model="value3" />
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                      <vs-button
                        color="dark"
                        type="gradient"
                        icon="search"
                        @click="search('batch')"
                      ></vs-button>
                    </vs-col>
                  </vs-row>
                </vs-dropdown-menu>
              </vs-dropdown>
              <vs-button
                color="primary"
                radius
                type="filled"
                @click="addReceipt"
                icon="add"
                style="margin-right: 1%; top: 1.5px;"
              ></vs-button>
              <vs-button
                color="primary"
                radius
                type="filled"
                icon="refresh"
                @click="mountedfunc()"
                style="margin-right: 1%; top: 1.5px;"
              ></vs-button>
            </vs-col>
          </vs-row>
          <vs-table max-items="10" :data="this.$store.state.MwbLeads">
            <template slot="thead">
              <vs-th sort-key="candidate_name">NAME</vs-th>
              <vs-th sort-key="batch">BATCH</vs-th>
              <vs-th>CLASSES</vs-th>
              <vs-th sort-key="agred_fees">AGREED FEES</vs-th>
              <vs-th sort-key="add_on_fees">ADD ON FEES</vs-th>
              <vs-th sort-key="recd">RECD</vs-th>
              <vs-th sort-key="pdcs">PDCS</vs-th>
              <vs-th>DUE</vs-th>
              <vs-th>BOOKS</vs-th>
              <vs-th>OTB</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>{{ tr.cma.person_name }}</vs-td>

                <vs-td :data="tr.batch">{{ tr.cma.batch }}</vs-td>

                <vs-td :data="tr.classes_types">{{ tr.classes_types }}</vs-td>

                <vs-td :data="tr.Total_Fees">{{ tr.fees.prof_add_on_fees }}</vs-td>

                <vs-td :data="tr.Publ_Addon_Fees">
                  <!-- <vs-chip :color='fee_badge_color(data[indextr].Publ_Addon_Fees)'> -->
                  {{ tr.fees.publ_add_on_fees }}
                  <!-- </vs-chip> -->
                </vs-td>

                <vs-td :data="tr.Recd">{{ tr.fees.total_fees }}</vs-td>

                <vs-td :data="tr.Payment_Extended_Deadline">{{ tr.fees.fees_due }}</vs-td>

                <vs-td :data="tr.Fee_Due">{{ tr.fees.payment_extended_deadline }}</vs-td>

                <vs-td :data="tr.BOOK">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="25"
                    height="25"
                    viewBox="0 0 192 192"
                    style=" fill:#000000;"
                  >
                    <g
                      fill="none"
                      fill-rule="nonzero"
                      stroke="none"
                      stroke-width="1"
                      stroke-linecap="butt"
                      stroke-linejoin="miter"
                      stroke-miterlimit="10"
                      stroke-dasharray
                      stroke-dashoffset="0"
                      font-family="none"
                      font-weight="none"
                      font-size="none"
                      text-anchor="none"
                      style="mix-blend-mode: normal"
                    >
                      <path d="M0,192v-192h192v192z" fill="none" />
                      <g :fill="fee_badge_color(tr.BOOK)">
                        <g id="surface1">
                          <path
                            d="M168.96,184.32h-122.88c-12.705,0 -23.04,-9.615 -23.04,-21.435v-132.165c0,-12.705 10.335,-23.04 23.04,-23.04h122.88v142.08h-122.88c-8.61,0 -15.36,5.76 -15.36,13.125c0,7.455 7.035,13.755 15.36,13.755h122.88z"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </vs-td>
                <vs-td :data="tr.OTB_STATUS">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="24"
                    height="24"
                    viewBox="0 0 192 192"
                    style=" fill:#000000;"
                  >
                    <g
                      fill="none"
                      fill-rule="nonzero"
                      stroke="none"
                      stroke-width="1"
                      stroke-linecap="butt"
                      stroke-linejoin="miter"
                      stroke-miterlimit="10"
                      stroke-dasharray
                      stroke-dashoffset="0"
                      font-family="none"
                      font-weight="none"
                      font-size="none"
                      text-anchor="none"
                      style="mix-blend-mode: normal"
                    >
                      <path d="M0,192v-192h192v192z" fill="none" />
                      <g :fill="feesColor(tr.OTB_STATUS)">
                        <path
                          d="M177.368,90.248l-15.232,-15.744l3.064,-21.688c0.624,-4.416 -2.368,-8.528 -6.76,-9.304l-21.576,-3.784l-10.264,-19.344c-2.08,-3.928 -6.92,-5.504 -10.92,-3.544l-19.68,9.616l-19.68,-9.616c-4,-1.96 -8.84,-0.384 -10.928,3.552l-10.264,19.352l-21.576,3.784c-4.392,0.768 -7.384,4.88 -6.76,9.296l3.064,21.688l-15.232,15.744c-3.104,3.208 -3.104,8.288 0,11.496l15.232,15.744l-3.064,21.688c-0.624,4.416 2.368,8.528 6.76,9.304l21.576,3.784l10.264,19.344c2.088,3.936 6.928,5.512 10.936,3.552l19.672,-9.624l19.68,9.616c4.008,1.96 8.84,0.384 10.936,-3.552l10.264,-19.344l21.576,-3.784c4.392,-0.768 7.384,-4.888 6.76,-9.304l-3.064,-21.688l15.232,-15.744c3.08,-3.2 3.08,-8.288 -0.016,-11.496zM88,131.312l-29.656,-29.656l11.312,-11.312l18.344,18.344l42.344,-42.344l11.312,11.312z"
                        />
                      </g>
                    </g>
                  </svg>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <vs-row style="margin-top: 3%">
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
              <vs-pagination :total="10" v-model="currentTablePage"></vs-pagination>
            </vs-col>
          </vs-row>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import LeadsNavBar from "../components/pagesComponents/LeadsNavBar.vue";
import EventBus from "../components/eventbus.js";
import vSelect from "vue-select";
import "vue-tree-halower/dist/halower-tree.min.css";
// import { VTree, VSelectTree } from "vue-tree-halower";
import axios from "axios";
import moment from "moment";
import constants from "../../constants.json";
export default {
  components: {
    LeadsNavBar,
    "v-select": vSelect
  },
  watch: {
    select1: function(value) {
      this.feesBooks = [];
      if (value === "All") {
        this.$store.state.fees_books.forEach(book => {
          this.feesBooks.push(book);
        });
      } else {
        this.$store.state.fees_books.forEach(book => {
          if (book.Collection_Status === value) {
            this.feesBooks.push(book);
          }
        });
      }
    },
    select2: function(value) {
      this.feesBooks = [];
      if (value === "All") {
        this.$store.state.fees_books.forEach(fees => {
          this.feesBooks.push(fees);
        });
      } else {
        this.$store.state.fees_books.forEach(fees => {
          if (fees.Collection_Status === value) {
            this.feesBooks.push(fees);
          }
        });
      }
    },
    select3: function(value) {
      this.feesBooks = [];
      if (value === "All") {
        this.$store.state.fees_books.forEach(book => {
          this.feesBooks.push(book);
        });
      } else {
        this.$store.state.fees_books.forEach(book => {
          if (book.Collection_Status === value) {
            this.feesBooks.push(book);
          }
        });
      }
    },
    currentTablePage(value) {
      this.initialFetchCandidates(value);
    }
  },
  data() {
    return {
      searchIn: {
        id: "",
        name: ""
      },
      select2: "",
      select3: "",
      value2: "",
      value1: "",
      value3: "",
      // selected: 'ALL',
      currentTablePage: 1,
      feesBooks: [],
      fees: [],
      books: [],
      options1: [
        { id: 1, label: "All" },
        { id: 2, label: "Cleared" },
        { id: 3, label: "Default" },
        { id: 4, label: "Partial" }
      ],
      options2: [
        { id: 1, label: "All" },
        { id: 2, label: "Cleared" },
        { id: 3, label: "Default" },
        { id: 4, label: "Partial" }
      ],
      options3: [
        { id: 1, label: "All" },
        { id: 2, label: "Cleared" },
        { id: 3, label: "Default" },
        { id: 4, label: "Partial" }
      ],
      select1: { id: 1, label: "All" },
      fees_filter: { id: 1, label: "All" },
      books_filter: { id: 1, label: "All" }
    };
  },

  mounted() {
    // this.$store.state.allCandidatesRaw.forEach((candid) => {
    //   if (candid.category === 'ewb') {
    //     this.EWBCandidates.push(candid)
    //     this.EWBCandidatesRaw.push(candid)
    //   }
    // })
    this.getSpoc();
    // this.initialFetchCandidates()
  },
  methods: {
    openPopupEmit(payload) {
      console.log("the payload", payload);
      this.$vs.loading();
      let url = `${constants.SERVER_API}getMwbDetailsForEwb?person_id=${payload.cpa.person_id}&mwb_id=${payload.cpa.mwb_id}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          this.$store.commit("UPDATE_NEW_DATA", [
            payload,
            response.data.engagements,
            response.data.mobile,
            response.data.emails,
            response.data.mwb
          ]);
          EventBus.$emit("open-student-report-popup", payload);
          this.$vs.loading.close();
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    nodechecked(node, v) {
      alert("that a node-check envent ..." + node.title + v);
    },
    async asyncLoad(node) {
      console.log("clicked");
      const { checked = false } = node;
      this.$set(node, "loading", true);
      let pro = await new Promise(resolve => {
        setTimeout(resolve, 2000, [
          { title: "test1", async: true },
          { title: "test2", async: true },
          { title: "test3" }
        ]);
      });
      if (!node.hasOwnProperty("children")) {
        this.$set(node, "children", []);
      }
      node.children.push(...pro);
      this.$set(node, "loading", false);
      if (checked) {
        this.$refs.tree.childCheckedHandle(node, checked);
      }
    },
    clear() {
      this.value1 = "";
      this.value2 = "";
    },
    convertTimestampToDate(timestamp) {
      if (timestamp !== "") {
        let date = moment.unix(timestamp).format("DD-MM-YYYY");
        return date;
      }
      return "";
    },
    getSpoc() {
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          this.rawBde = response.data.spocs;
          // this.treeDataBDE[0].children = [];
          // response.data.spocs.forEach(data => {
          //   var obj = {
          //     id: data.first_name,
          //     label: data.first_name
          //   };
          //   this.treeDataBDE[0].children.push(obj);
          // });
          // this.initSelectedBDE.push(this.searchIn.name);
          this.initialFetchCandidates(1);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    initialFetchCandidates(currentTablePage) {
      let obj = {
        spoc_ids: "",
        page: currentTablePage
      };
      // obj.spoc_ids = this.searchIn.id;
      obj.spoc_ids = 75;
      axios
        .get(`${constants.SERVER_API}getCmaCandidates`, {
          params: obj,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`
          }
        })
        .then(response => {
          console.log("candiadtes data", response.data);
          this.mountLeadsToStore(response);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    mountLeadsToStore(response) {
      response.data.data.forEach(lead => {
        // lead.cpa.classes_attended_aug = lead.attendance.aud;
        // lead.cpa.classes_attended_bec = lead.attendance.bec;
        // lead.cpa.classes_attended_far = lead.attendance.far;
        // lead.cpa.classes_attended_reg = lead.attendance.reg;
        lead.engagements = [];
        lead.mobile = [];
        lead.emails = [];
        // lead.cpa.mwb = [];
        // lead = lead.cpa;
        // lead.cpa.attendance = lead.attendance;
        // lead.cpa.latest_engagement = lead.mwb;
      });
      this.currentTablePage = response.data.current_page;
      this.tablelinks = response.data.last_page;
      this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
    },
    search(type) {
      this.value1 = "";
      this.value2 = "";
      if (type === "name") {
        this.feesBooks = [];
        this.$store.state.fees_books.forEach(book => {
          if (book.candidate_name === this.value1) {
            this.feesBooks.push(book);
          }
        });
      } else if (type === "course") {
        this.feesBooks = [];
        this.$store.state.fees_books.forEach(book => {
          if (book.batch === this.value2) {
            this.feesBooks.push(book);
          }
        });
      }
    },
    mountedfunc() {
      // this.EWBCandidates = [];
      // this.EWBCandidatesRaw.forEach(raw => {
      //   this.EWBCandidates.push(raw);
      // });
      EventBus.$emit("refresh-focus");
      this.value1 = "";
      this.value2 = "";
      this.value3 = "";
      this.value4 = "";
      this.value5 = "";
      this.infosearch = [];
      this.initSelectedBDE = [];
      this.initSelectedCourses = [];
      this.initSelectedLevels = [];
      // this.dateRange.startDate = "";
      // this.dateRange.endDate = "";
      this.getSpoc();
    },
    sortEmit(payload) {
      this.selected = payload.toUpperCase();
      this.$store.commit("SORT_BOOKS_TABLE", payload);
    },
    feesColor(Otb_Status) {
      if (Otb_Status == "Not_received") {
        return "#E74C3C";
      } else {
        return "#2ECC71";
      }
    },
    addReceipt() {
      EventBus.$emit("open-fees-books-popup");
    },
    fee_badge_color(dueBooks) {
      if (dueBooks !== "") {
        return "#2ECC71";
      } else if (dueBooks == "") {
        return "#E74C3C";
      }
    }
  }
};
</script>

<style lang="css">
.selectExample {
  margin: 10px;
}
.con-select-example {
  display: flex;
  align-items: center;
  justify-content: center;
}
.con-select .vs-select {
  width: 100%;
}
@media (max-width: 550px) {
  .con-select {
    flex-direction: column;
  }
  .con-select .vs-select {
    width: 100%;
  }
}
.vs-table--tbody-table tr {
  font-family: sans-serif;
  /* font-size: smaller; */
}
</style>